<template>
  <div class="microbialDetection">
    <!-- 头部 -->
    <swipeTop
      :top_img="top_img"
      :ZHtitle="ZHtitle"
      :ENtitle="ENtitle"
    ></swipeTop>
    <!-- 搜索框 -->
    <seachVue></seachVue>
    <!-- 导航标签 -->
    <classifyList2Vue
      :checkindex="checkindex"
      :toptype="toptype"
    ></classifyList2Vue>
    <!-- 标签 -->
    <!-- <tag></tag> -->
    <!-- 内容 -->
    <div class="listContent">
      <template v-if="subMenuList">
        <div
          v-bind:style="{ backgroundImage: 'url(' + item.pic + ')' }"
          v-for="(item, index) in subMenuList"
          @click="tofourPage(item)"
          :key="index"
          class="listItem"
        >
          <div class="itemTitle">
            <div class="line"></div>
            <span>{{ item.menuName }}</span>
          </div>
          <div class="label">
            <div v-if="item.subMenuList != []">
              <ul>
                <li v-for="(item1, index1) in item.subMenuList" :key="index1">
                  {{ item1.menuName }}
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li v-for="(item1, index1) in item.productList" :key="index1">
                  {{ item1.productName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
      <div v-else>
        <listVue
          @todetaill="todetaill"
          :listTitle="listTitle"
          :projuctList="productList"
        ></listVue>
      </div>
      <!-- <div class="showtext" v-if="showText"><span>数据更新中...</span></div> -->
    </div>
  </div>
</template>

<script>
import swipeTop from "@/components/swipeTop.vue";
import seachVue from "@/components/seachVue.vue";
import tag from "@/components/tag";
// import classifyList from "@/components/classifyList.vue";
import classifyList2Vue from "@/components/classifyList2.vue";
import { genMenuInfo } from "@/api/path";
import { HappyScroll } from "vue-happy-scroll";
import listVue from "../../components/listVue.vue";
import "vue-happy-scroll/docs/happy-scroll.css";
export default {
  components: {
    swipeTop,
    seachVue,
    listVue,
    classifyList2Vue,
    HappyScroll,
    tag,
  },
  data() {
    return {
      menuId: this.$route.query.menuId, // 获取页面id
      ParentId: -1, // 获取父级id
      top_img: "", // 头部背景图
      ZHtitle: "", // 中文标题
      ENtitle: "", // 英文标题
      listTitle: "",
      moduleList: [], // 分类标题
      subMenuList: [],
      productList: [],
      showText: false,
      toptype: [], // 导航标签数据
      bgcolor: "",
      checkindex: Number(this.$route.query.menuId), //导航标签选中
    };
  },
  watch: {
    $route: function (newQuestion, oldQuestion) {
      this.menuId = newQuestion.query.menuId;
      this.checkindex = Number(newQuestion.query.menuId);
      this.getAllMenu();
    },
  },
  mounted() {
    // 监听移动端返回
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.backButton, false);
    }
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    // 获取数据
    getAllMenu() {
      genMenuInfo(this.menuId)
        .then((res) => {
          this.ZHtitle = res.data.menuName; // 获取中文标题
          this.ENtitle = res.data.menuEnglish; // 获取英文标题
          this.top_img = res.data.background; // 获取头部背景图片
          this.subMenuList = res.data.subMenuList; // 获取子分类
          this.productList = res.data.productList;
          this.ParentId = res.data.parentId; // 获取父级id
          if (this.ParentId == 5) {
            this.bgcolor = "#00078c";
          }
          this.toptype = res.data.classificationMessageAlways; //获取标签分类
          if (!res.data.subMenuList) {
            this.showText = true;
          } else {
            this.showText = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 四级页面
    tofourPage(item) {
      this.$router.push({
        path: "/levelFivePageHDXX",
        query: { menuId: item.menuId, checkindex: this.checkindex },
      });
      // 判断是否是品牌介绍页面
      // if (item.productList) {
      //   if(item.productList.length === 1){
      //       this.$router.push({
      //         path: "/detailsPage",
      //         query: { menuid: item.productList[0].productId },
      //       });
      //   }else{
      //     this.$router.push({
      //       path: "/levelFivePage",
      //       query: { menuId: item.menuId, checkindex: this.checkindex },
      //     });
      //   }
      // } else {
      //     this.$router.push({
      //       path: "/levelFourPages",
      //       query: { menuId: item.menuId, checkindex: this.checkindex },
      //     });
      // }
    },
    // 返回事件处理
    backButton() {
      if (this.ParentId === 5) {
        this.$router.replace({
          path: "/secondaryPagesHDXX",
          query: { mid: this.ParentId },
        });
      } else if (this.ParentId === 7 || this.ParentId === 8) {
        this.$router.replace({
          path: "/secondaryPagesYYLY",
          query: { mid: this.ParentId },
        });
      } else if (this.ParentId === 4) {
        this.$router.replace({
          path: "/secondaryPagesPPJS",
          query: { mid: this.ParentId },
        });
      } else {
        this.$router.replace({
          path: "/secondaryPages",
          query: { mid: this.ParentId },
        });
      }
    },
    todetaill(item) {
      if (item.fileType === 4) {
        location.href = item.fileUrl;
      }
    },
  },

  destroyed() {
    // 销毁页面返回事件
    window.removeEventListener("popstate", this.backButton, false);
  },
};
</script>

<style lang="scss" scoped>
.microbialDetection {
  height: 100vh;
}
.listContent {
  width: 100%;
  height: 56.8%;
  overflow-y: scroll;
  background-color: #182389;
  .listItem {
    width: 100%;
    height: 23%;
    display: flex;
    background-size: 100% 100%;
    justify-content: space-between;
    ::-webkit-scrollbar {
      width: 5px !important;
      background-color: #999999;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #eae7e7;
    }
    .itemTitle {
      padding: 10px 20px;
      color: white;
      .line {
        width: 18%;
        height: 0.8vw;
        margin: 8px 2px;
        background-color: rgb(173, 173, 173);
      }
      span {
        font-size: 3.5vw;
        font-weight: 550;
      }
    }
  }
  .label {
    font-size: 3vw;
    color: #ffffff;
    display: flex;
    width: 70%;
    padding: 6px 0;
    height: 85%;
    overflow-y: auto;
    position: relative;
    right: 8px;
    margin: 5px 0;
    ul {
      padding-left: 3px;
      list-style: disc;
    }
    li {
      margin-right: 35px;
      margin-left: 25px;
      list-style-position: outside;
      // list-style-position: outside;
    }
  }
}
.classifyList {
  background-color: #d81722;
  font-size: 3vw;
  color: #fff;
  width: 100%;
  height: 6vh;
  .classList {
    text-align: center;
    display: flex;
    align-items: center;
    height: 100%;
    :not(:nth-last-child(1)) {
      border-right: 1px solid #fff;
    }
    .classItem {
      width: 24.7%;
      height: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        height: 80%;
        width: 85%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.checkSty {
  background-color: #bf545a;
}
.showtext {
  color: #fff;
  font-size: 3vw;
  text-align: center;
  margin-top: 30px;
}
</style>
<style>
</style>
